import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useErrorsStore = defineStore('errorsstore', () => {
  const errors = ref({})

  const setErrors = (err) => {
    errors.value = err
  }

  return {
    errors,
    setErrors,
  }
}, { persist:false })
